import { User } from '@src/API';

export const getAvgRating = (user: User | null | undefined) => {
  if (user && user.seller_star_quantity && user.seller_review_quantity) {
    const rating = user.seller_star_quantity / user.seller_review_quantity;
    return rating.toFixed(1);
  }
  return null;
};

export const getCommissionCompletedRatePercentage = (user: User | null | undefined) => {
  // Calculate when commission_completed_quantity is neither null nor undefined, AND
  // the denominator is positive
  if (
    user &&
    user.commission_completed_quantity !== null &&
    user.commission_completed_quantity !== undefined
  ) {
    const denominator =
      user.commission_completed_quantity +
      (user.commission_cancelled_mutually_quantity || 0) +
      (user.commission_cancelled_seller_fault_quantity || 0);
    if (denominator > 0) {
      const completedRate = (user.commission_completed_quantity / denominator) * 100;
      return Math.round(completedRate);
    }
  }
  return null;
};

export const getCommissionCompletedRate = (user: User | null | undefined) => {
  const completedRatePercentage = getCommissionCompletedRatePercentage(user);
  if (completedRatePercentage !== null) {
    return completedRatePercentage.toFixed(0);
  }
  return null;
};

export const getCommissionCompletedOnTimeRatePercentage = (user: User | null | undefined) => {
  // Calculate when commission_completed_on_time_quantity is neither null nor undefined
  if (
    user &&
    user.commission_completed_quantity &&
    user.commission_completed_on_time_quantity !== null &&
    user.commission_completed_on_time_quantity !== undefined
  ) {
    const ontimeRate =
      (user.commission_completed_on_time_quantity / user.commission_completed_quantity) * 100;
    return Math.round(ontimeRate);
  }
  return null;
};

export const getCommissionCompletedOnTimeRate = (user: User | null | undefined) => {
  const ontimeRatePercentage = getCommissionCompletedOnTimeRatePercentage(user);
  if (ontimeRatePercentage !== null) {
    return ontimeRatePercentage.toFixed(0);
  }
  return null;
};

export const getCommissionAverageLateTime = (user: User | null | undefined) => {
  if (
    user?.commission_completed_quantity === null ||
    user?.commission_completed_quantity === undefined ||
    user?.commission_cumulative_late_seconds === null ||
    user?.commission_cumulative_late_seconds === undefined
  ) {
    return 0;
  }
  // AverageLate = Total late time / total completed commission
  const averageLate =
    user?.commission_cumulative_late_seconds / user?.commission_completed_quantity;

  return averageLate;
};

export const getLateTimeLabel = (user: User, lateTime: number | null | undefined) => {
  // case "chưa có"
  if (
    lateTime === null ||
    lateTime === undefined ||
    user?.commission_completed_quantity === null ||
    user?.commission_completed_quantity === undefined
  ) {
    return 'Chưa có';
  }
  // case 'k trễ', on time rate = 100%
  if (user.commission_completed_quantity === user.commission_completed_on_time_quantity) {
    return 'K trễ';
  }
  // convert seconds to days
  const lateTimeInDays = Math.floor(lateTime / (60 * 60 * 24));
  if (lateTimeInDays < 1) {
    return '<1N';
  }
  // default case
  return `${lateTimeInDays}N`;
};

export const getLateTimeRate = (user: User, lateTime: number | null | undefined) => {
  // case "chưa có"
  if (
    lateTime === null ||
    lateTime === undefined ||
    user?.commission_completed_quantity === null ||
    user?.commission_completed_quantity === undefined
  ) {
    return null;
  }
  // case "k trễ"
  if (user.commission_completed_quantity === user.commission_completed_on_time_quantity) {
    return 100;
  }
  // convert seconds to days
  const lateTimeInDays = Math.floor(lateTime / (60 * 60 * 24));
  return ((30 - lateTimeInDays) * 100) / 30;
};

export const getResponseOnTimeRate = (user: User | null | undefined) => {
  if (
    !user ||
    // user?.message_response_late_quantity === null || // we can still calculate average response time if late quantity is null
    user?.message_response_on_time_quantity === null ||
    // user?.message_response_late_quantity === undefined || // we can still calculate average response time if late quantity is null
    user?.message_response_on_time_quantity === undefined
  ) {
    return null;
  }

  // calculate response ontime rate 0-100%
  const responseRate =
    user.message_response_on_time_quantity /
    ((user.message_response_late_quantity || 0) + user.message_response_on_time_quantity);
  return Math.round(responseRate * 100);
};

export const getAverageResponseTime = (user: User | null | undefined) => {
  // case "chưa có"
  if (
    !user ||
    // user?.message_response_late_quantity === null || // we can still calculate average response time if late quantity is null
    user?.message_response_on_time_quantity === null ||
    user?.message_cumulative_response_seconds === null ||
    // user?.message_response_late_quantity === undefined || // we can still calculate average response time if late quantity is null
    user?.message_response_on_time_quantity === undefined ||
    user?.message_cumulative_response_seconds === undefined
  ) {
    return null;
  }
  // convert seconds to hours
  const responseTimeInHours = user.message_cumulative_response_seconds / (60 * 60);

  // calculate average response time
  const averageResponseTime =
    responseTimeInHours /
    ((user.message_response_late_quantity || 0) + user.message_response_on_time_quantity);
  return Math.round(averageResponseTime);
};

export const getAverageResponseTimeRate = (averageResponseTime: number | null | undefined) => {
  if (averageResponseTime === null || averageResponseTime === undefined) {
    return null;
  }

  // calculate average response time rate 0-24h -> 100-0%
  const maxResponseTime = averageResponseTime > 24 ? 24 : averageResponseTime;
  return Math.round(100 - (maxResponseTime / 24) * 100);
};
