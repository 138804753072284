import Drawer from '@mui/material/Drawer';
import usePageBottom from '@src/hooks/usePageBottom';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { MD_SCREEN_IN_PX } from '@src/utils/constants';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import FooterResources, {
  DisputeResolution,
  PaymentTerms,
  PrivacyPolicy,
  SecurityWallOfFame,
  TermsOfUse,
} from './FooterResources';

const DefaultSpanClassName = 'text-xs md:text-s-tall';
const DefaultLinkClassName = 'text-xs md:text-s-tall hover:underline';

type FooterProps = {
  // Whether to enable a sticky "footer" that always shows, except when user
  // scrolls to the end of the page. This is a sticky bottom app bar that
  // shows some key links, with an expand button. This is used on pages with
  // infinite feed, where users can only get to real footer after scrolling
  // all the way to the bottom of the page.
  // Reference: https://airbnb.com (home page)
  isStickyFooterEnabled?: boolean;
};

/**
 * A footer component that provides links and information about the site.
 */
export default function Footer(props: FooterProps) {
  const { isStickyFooterEnabled } = props;
  // Check whether user has reached the bottom of the page. When user is at the
  // bottom of the page, hide the sticky footer to avoid conflict with the real
  // footer.
  // We use 320px (a rough estimate of the height of the real footer)
  const isAtPageBottom = usePageBottom(320);

  // Whether the bottom drawer is open (expanded)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [windowInnerWidth, setWindowInnerWidth] = useState<number | null | undefined>(null);

  // Show sticky footer or not depending on the width of the screen
  const { width } = useWindowDimensions();

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    setWindowInnerWidth(width);
  }, [width]);

  return (
    <div className="w-full">
      {/* A footer shown at the bottom of the page */}
      <footer className="flex flex-col w-full px-4 md:px-12 items-center bg-gray-50 border-t border-greyscale-g3">
        <FooterResources />
        <div className="flex items-center justify-between border-t border-greyscale-g3 w-full py-6">
          <div className="flex flex-col md:flex-row items-start">
            <span className={DefaultSpanClassName}>© 2023 Dreamerly.com</span>
            <div className="flex items-center flex-wrap">
              <span className="hidden md:inline text-xs md:text-s-tall">
                &nbsp;&nbsp;·&nbsp;&nbsp;
              </span>
              <a href={TermsOfUse.href}>
                <span className={DefaultLinkClassName}>{TermsOfUse.text}</span>
              </a>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <a href={PaymentTerms.href}>
                <span className={DefaultLinkClassName}>{PaymentTerms.abbreviated}</span>
              </a>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <a href={PrivacyPolicy.href}>
                <span className={DefaultLinkClassName}>{PrivacyPolicy.abbreviated}</span>
              </a>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <a href={DisputeResolution.href}>
                <span className={DefaultLinkClassName}>{DisputeResolution.abbreviated}</span>
              </a>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <a href={SecurityWallOfFame.href}>
                <span className={DefaultLinkClassName}>{SecurityWallOfFame.abbreviated}</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
      {/* A sticky "footer" that always shows on page, except when user reaches
      the bottom of the page */}
      {isStickyFooterEnabled &&
        windowInnerWidth &&
        windowInnerWidth >= MD_SCREEN_IN_PX &&
        !isAtPageBottom && (
          <div
            className="flex z-20 fixed bottom-0 w-full px-12 py-3 bg-gray-50
          border-t border-greyscale-g3 items-center justify-between"
          >
            <div className="flex items-center">
              <span className="text-s">© 2023 Dreamerly.com</span>
              <span className="text-s">&nbsp;&nbsp;·&nbsp;&nbsp;</span>
              <a href={TermsOfUse.href}>
                <span className="text-s-tall hover:underline">{TermsOfUse.abbreviated}</span>
              </a>
              <span className="text-s">&nbsp;&nbsp;·&nbsp;&nbsp;</span>
              <a href={PaymentTerms.href}>
                <span className="text-s-tall hover:underline">{PaymentTerms.abbreviated}</span>
              </a>
              <span className="text-s">&nbsp;&nbsp;·&nbsp;&nbsp;</span>
              <a href={PrivacyPolicy.href}>
                <span className="text-s-tall hover:underline">{PrivacyPolicy.abbreviated}</span>
              </a>
              <span className="text-s">&nbsp;&nbsp;·&nbsp;&nbsp;</span>
              <a href={DisputeResolution.href}>
                <span className="text-s-tall hover:underline">{DisputeResolution.abbreviated}</span>
              </a>
              <span className="text-s">&nbsp;&nbsp;·&nbsp;&nbsp;</span>
              <a href={SecurityWallOfFame.href}>
                <span className="text-s-tall hover:underline">
                  {SecurityWallOfFame.abbreviated}
                </span>
              </a>
            </div>
            <button className="flex items-center" onClick={() => setIsDrawerOpen(true)}>
              <span className="text-s font-semibold underline mr-2">Hỗ trợ và tài nguyên</span>
              <Image src="/ic-chevron-up.svg" height={16} width={16} alt="Expand icon" />
            </button>
            <Drawer
              variant="temporary"
              anchor="bottom"
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                '& .MuiDrawer-paper': {
                  borderRadius: '12px',
                },
              }}
              open={isDrawerOpen}
              onClose={handleToggleDrawer}
            >
              <div className="px-12">
                <FooterResources onCloseButton={handleToggleDrawer} />
              </div>
            </Drawer>
          </div>
        )}
    </div>
  );
}
