import Image from 'next/image';
import Link from 'next/link';

type FooterColumnLinkItemProps = {
  text: string;
  abbreviated?: string;
  href: string;
  type: 'next/link' | 'a' | 'mailto' | 'tel';
};

export const TermsOfUse: FooterColumnLinkItemProps = {
  text: 'Điều khoản sử dụng',
  abbreviated: 'Điều khoản sử dụng',
  href: 'https://dreamerly.com/help/vi/articles/8140148-di%E1%BB%81u-kho%E1%BA%A3n-s%E1%BB%AD-d%E1%BB%A5ng',
  type: 'a',
};

export const PaymentTerms: FooterColumnLinkItemProps = {
  text: 'Chính sách thanh toán',
  abbreviated: 'Thanh toán',
  href: 'https://dreamerly.com/help/vi/articles/8140154-chinh-sach-thanh-toan',
  type: 'a',
};

export const PrivacyPolicy: FooterColumnLinkItemProps = {
  text: 'Chính sách bảo mật',
  abbreviated: 'Bảo mật',
  href: 'https://dreamerly.com/help/vi/articles/8143568-chinh-sach-b%E1%BA%A3o-m%E1%BA%ADt',
  type: 'a',
};

export const DisputeResolution: FooterColumnLinkItemProps = {
  text: 'Giải quyết khiếu nại',
  abbreviated: 'Khiếu nại',
  href: 'https://dreamerly.com/help/vi/articles/8143570-gi%E1%BA%A3i-quy%E1%BA%BFt-khi%E1%BA%BFu-n%E1%BA%A1i',
  type: 'a',
};

export const SecurityWallOfFame: FooterColumnLinkItemProps = {
  text: 'Security Wall of Fame',
  abbreviated: 'Wall of Fame',
  href: 'https://dreamerly.com/help/vi/articles/9445189-security-wall-of-fame',
  type: 'a',
};

const TermsColumnLinkItems: FooterColumnLinkItemProps[] = [
  {
    text: TermsOfUse.text,
    href: TermsOfUse.href,
    type: TermsOfUse.type,
  },
  {
    text: PaymentTerms.text,
    href: PaymentTerms.href,
    type: PaymentTerms.type,
  },
  {
    text: PrivacyPolicy.text,
    href: PrivacyPolicy.href,
    type: PrivacyPolicy.type,
  },
  {
    text: DisputeResolution.text,
    href: DisputeResolution.href,
    type: DisputeResolution.type,
  },
  {
    text: SecurityWallOfFame.text,
    href: SecurityWallOfFame.href,
    type: SecurityWallOfFame.type,
  },
];

const SellerLinkItems: FooterColumnLinkItemProps[] = [
  {
    text: 'Hướng dẫn chào giá',
    href: 'https://www.facebook.com/dreamerlyapp',
    type: 'a',
  },
  {
    text: 'Hướng dẫn nhận thanh toán',
    href: 'https://www.facebook.com/dreamerlyapp',
    type: 'a',
  },
];

const CompanyLinkItems: FooterColumnLinkItemProps[] = [
  {
    text: 'Trung tâm trợ giúp',
    href: 'https://dreamerly.com/help',
    type: 'a',
  },
  {
    text: 'Facebook',
    href: 'https://www.facebook.com/dreamerlyapp',
    type: 'a',
  },
  {
    text: 'Zalo',
    href: 'https://zalo.me/0387188958',
    type: 'a',
  },
  {
    text: 'Số điện thoại',
    href: 'tel:+84 387 188 958',
    type: 'a',
  },
];

const FooterColumnLinkItem = ({ text, href, type }: FooterColumnLinkItemProps) => {
  switch (type) {
    case 'next/link':
      return (
        <Link href={href} passHref prefetch={false}>
          <a>
            <span className="text-s-tall hover:underline">{text}</span>
          </a>
        </Link>
      );
    case 'a':
      return (
        <a href={href}>
          <span className="text-s-tall hover:underline">{text}</span>
        </a>
      );
    case 'mailto':
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <span className="text-s-tall hover:underline">{text}</span>
        </a>
      );
    case 'tel':
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <span className="text-s-tall hover:underline">{text}</span>
        </a>
      );
  }
};

type FooterColumnProps = {
  title: string;
  linkItems: FooterColumnLinkItemProps[];
};

const FooterColumn = ({ title, linkItems }: FooterColumnProps) => {
  return (
    <div className="flex flex-col">
      <h1 className="text-m font-medium">{title}</h1>
      <div className="flex flex-col pt-4 space-y-2">
        {linkItems.map((item) => (
          <FooterColumnLinkItem key={item.text} {...item} />
        ))}
      </div>
    </div>
  );
};

const ClassNameWithButton = 'pb-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-full gap-6';
const ClassNameWithoutButton = 'py-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-full gap-6';

export default function FooterResources({ onCloseButton }: { onCloseButton?: () => void }) {
  return (
    <div className="flex flex-col w-full">
      {!!onCloseButton && (
        <button
          className="mt-3 flex p-2 hover:bg-greyscale-g1 w-fit rounded-full self-end"
          onClick={onCloseButton}
        >
          <Image src="/ic-close.svg" width={20} height={20} />
        </button>
      )}
      <div className={onCloseButton ? ClassNameWithButton : ClassNameWithoutButton}>
        <div className="flex flex-col">
          <h1 className="text-m font-medium">Công ty TNHH Tinai Technologies</h1>
          <div className="flex flex-col pt-4">
            <span className="text-xs leading-6">Mã số thuế: 0317004758 · Cấp ngày 1/11/2022</span>
            <span className="text-xs leading-6"></span>
            <span className="text-xs leading-6">Tại Sở Kế hoạch và Đầu tư TP. Hồ Chí Minh</span>
            <span className="text-xs leading-6">
              Địa chỉ: L17-11, 72 Lê Thánh Tôn, Q1, TP. Hồ Chí Minh
            </span>
            <span className="text-xs leading-6">
              Email:{' '}
              <a
                href="mailto:help@dreamerly.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                help@dreamerly.com
              </a>
            </span>
          </div>
        </div>
        <FooterColumn title="Điều khoản" linkItems={TermsColumnLinkItems} />
        <FooterColumn title="Cho hoạ sĩ" linkItems={SellerLinkItems} />
        <FooterColumn title="Dreamerly" linkItems={CompanyLinkItems} />
      </div>
    </div>
  );
}
