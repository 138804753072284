import { useEffect, useState } from 'react';

/**
 *  A hook to detect if the user has reached the bottom of the page, within a given threshold.
 *
 * @param heightThreshold The height threshold in pixels from the bottom of the
 *  page that will make the hook return true
 * @returns boolean
 */
export default function usePageBottom(heightThreshold = 0) {
  const [hasReachedBottom, setHasReachedBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
      const isBottomReached = scrollHeight - scrollPosition <= heightThreshold;

      setHasReachedBottom(isBottomReached);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [heightThreshold]);

  return hasReachedBottom;
}
